"use client";
import React, { useState, useRef, useEffect } from "react";
import BlogCard from "~/components/reusables/blog-card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import PaginationComponent from "~/components/reusables/pagination-component";

export const BlogTopicList = ({ topic, blogs }) => {
  const blogListRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);
  const [sortOption, setSortOption] = useState("Latest");
  const blogsPerPage = 9;

  useEffect(() => {
    const initialPage = parseInt(
      localStorage.getItem("currentPage") || "1",
      10,
    );
    const max = Math.ceil(blogs.length / blogsPerPage);
    if (initialPage < max) {
      setCurrentPage(initialPage);
    }
  }, []);

  // Function to sort blogs
  const sortBlogs = (blogs, sortOption) => {
    return blogs.sort((a, b) => {
      const dateA = new Date(a.attributes.publishDate).getTime();
      const dateB = new Date(b.attributes.publishDate).getTime();
      return sortOption === "Latest" ? dateB - dateA : dateA - dateB;
    });
  };

  // Sorted blogs
  const sortedBlogs = sortBlogs([...blogs], sortOption);

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  // Get current page blogs
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = sortedBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setInitialLoad(false)
    if (typeof window !== "undefined") {
      localStorage.setItem("currentPage", pageNumber.toString());
    }
  };
  // Handle sort option change
  const handleSortChange = (value) => {
    setSortOption(value);
    setCurrentPage(1);
    if (typeof window !== "undefined") {
      localStorage.setItem("currentPage", "1");
    }
  };

  useEffect(() => {
    if (blogListRef.current && !initialLoad) {
      blogListRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [currentPage]);

  return (
    <div ref={blogListRef} className="container my-[60px]">
      <div className="container relative">
        <h6 className="font-h6 w-full py-3">SHOWING ARTICLES ABOUT</h6>
        <div className="mb-[50px] flex flex-col items-end gap-5 border-b-4 border-black py-3 lg:flex-row">
          <h1 className="font-hero-title">{decodeURIComponent(topic)}</h1>
          <p className="mb-2">{`(${blogs.length} RESULTS)`}</p>
        </div>
      </div>
      <div className="container relative my-[50px]">
        {blogs.length ? (
          <div>
            <div className="flex flex-col gap-5 md:flex-row md:items-center">
              <h5>Sort results by</h5>
              <Select onValueChange={handleSortChange}>
                <SelectTrigger className="h-[50px] w-[300px] border-black">
                  <SelectValue placeholder={sortOption} />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="Latest">Latest</SelectItem>
                  <SelectItem value="Earliest">Earliest</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="mt-[50px] grid grid-cols-1 gap-5 md:mt-[80px] md:grid-cols-2 md:gap-[38px] lg:grid-cols-3 lg:gap-[70px]">
              {currentBlogs.map((blog, i) => (
                <div
                  key={i}
                  className="relative min-h-[380px] md:min-h-[530px]"
                >
                  <BlogCard blog={blog} size="md" />
                </div>
              ))}
            </div>
            {totalPages > 1 && (
              <PaginationComponent
                totalPages={totalPages}
                currentPage={currentPage}
                paginate={paginate}
              />
            )}
          </div>
        ) : (
          <div>
            <h4 className="font-h4">No results found.</h4>
          </div>
        )}
      </div>
    </div>
  );
};
